<template>
  <div>
    <div class="relative pt-40 pb-20 px-0 md:px-12 lg:px-16 xl:px-24 bg-gradient-to-b from-primary/10">
      <div class="page-container">
        <div class="flex flex-wrap">
          <div class="w-full px-8 md:px-0 mb-8 md:mb-16 md:w-2/5 flex flex-col justify-center">
            <slot></slot>
          </div>
          <div class="w-full md:w-3/5">
            <div class="relative flex justify-center">
              <div class="absolute inset-0 z-20">
                <img src="~/assets/img/SpeechBubbleIcon.svg" class="animate__animated animate__jackInTheBox  absolute left-[28%] top-[28%] w-12 md:w-16" alt=""/>
                <img src="~/assets/img/SpeechBubbleText1.svg" class="animate__animated animate__jackInTheBox animate__delay-1s absolute right-[18%] top-[28%] w-24 md:w-40" alt=""/>
                <img src="~/assets/img/SpeechBubbleText2.svg" class="animate__animated animate__jackInTheBox animate__delay-2s absolute right-[18%] bottom-[20%] w-24 md:w-40" alt=""/>
              </div>
              <img class="relative z-10 w-[38%]" src="~/assets/img/homepage-hero-bg-phone.png" alt="" />
              <img class="absolute left-0 right-0 top-[50%] -translate-y-2/4 transform bottom-0"
                   src="~/assets/img/happiplans-homepage-collage.png" alt=""
                   width="1896"
                   height="1422"
               />
            </div>
            <div class="text-center mt-4">
              <NuxtLink :to="{'name': 'timeline-id', 'params': {'id': '9ab66f4c-78dc-4b72-80d4-54a2708af3e9'}}"
                        class="font-bold text-sm text-secondary underline uppercase tracking-wider">View example</NuxtLink>
            </div>
          </div>

        </div>
        <div class="absolute z-[12] right-0 left-0 bottom-8 flex justify-center">
          <span class="w-[2.5rem] h-[2.5rem] rounded-full inline-block flex items-center justify-center text-white bg-white border border-grey-200 animate__animated animate__pulse animate__infinite">
            <ArrowDownIcon @click="scrollToMainContent" class="w-4 h-4 text-grey-400 cursor-pointer"/>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'animate.css';
import ArrowDownIcon from "~/components/global/Icons/ArrowDownIcon.vue";

const scrollToMainContent = () => {
  const body = document.querySelector('body');
  const main = document.querySelector('main');
  if (body instanceof HTMLElement && main instanceof HTMLElement) {
    main.scrollIntoView({ behavior: 'smooth' });
  }
};

</script>

<style scoped>

</style>
